@font-face {
  font-family: "Hepta Slab";
  src: url("../fonts/HeptaSlab-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "MierB";
  src: url("../fonts/MierB-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'MierB';
  font-style:  normal;
  font-weight: 700;
  font-display: block;
  src: url("../fonts/MierB-Bold.woff2?v=3.11") format("woff2"),
       url("../fonts/MierB-Bold.woff?v=3.11") format("woff");
}

@font-face {
  font-family: "Space Mono";
  src: url("../fonts/SpaceMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/Inter-Bold.woff2") format("woff2"),
       url("../fonts/Inter-Bold.woff") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/Inter-Regular.woff2") format("woff2"),
       url("../fonts/Inter-Regular.woff") format("woff");
}

html,
body {
  -webkit-font-smoothing: antialiased;
  overflow-x:unset;  // position sticky won't work with overflow-x:hidden; on body tag - override for this template only
  background: #fff;
  color: #343741;
  line-height: 26px;
  font: {
    family: 'Inter', Arial, sans-serif;
    size: 1rem;
    weight: 400;
  }
}

.primary-navigation-section {
  border-bottom: 1px solid transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  box-sizing: border-box;
  display: block;
  position: relative;
  font-weight: 800;
  font-family: 'MierB', 'Inter', Arial, sans-serif;
  color: #343741;
  a {
    font-weight: 700;
    text-decoration: none;
    font-weight: unset;
    &:hover {
      text-decoration: none;
    }
  }
}

h1, .h1 {
  font-size: 72px;
  line-height: 120%;
  letter-spacing: -0.04em;
  padding-bottom: 32px;
  font-weight: 400;
}

h2, .h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  letter-spacing: -0.04em;
  padding-bottom: 16px;
}

h3, .h3 {
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 120%;
  letter-spacing: -0.04em;
}

h4, .h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 120%;
  padding-bottom: 16px;
}

h5, .h5 {
  font-size: 24px;
  line-height: 28px;
  padding-bottom: 16px;
  font-weight: 700;
}

h6, .h6 {
  font-size: 24px;
  line-height: 120%;
  padding-bottom: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: -0.02em;
}

p {
  margin: 0 0 8px 0;
  color: #343741 ; 
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  
  &.medium {
    font-size: 16px;
  }
  &.small {
    font-size: 12px;
    line-height: 150%;
    margin-bottom: 0;
  }
}

.primary-navigation {
  p {
    font-size: 16px;
    line-height: 26px;

    &.paragraph-medium {
      font-size: 14px;
      line-height: 24px;
    }
  }
}

.illustration-icon-grid-item {
  padding: 0 24px;
}

.col-12, .col-md-8, .col-md-4, .col-sm-5, .col-sm-6, .col-lg-6, .col-xl-7, .col-xs-12, .col-sm-4, .col-xl-5, .col-sm-8, .col-lg-12 {
  padding: 0 16px;
}

.row {
  margin: 0 -16px;
}

.paragraph-caption {
  font-size:14px;
  line-height:24px;
  margin-top: 16px;
  color: #343741;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  font-style: normal;
}

ul,
ul li {
  background: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  ul {
    margin-top: 4px;
  }
}

.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

.layout-00 {
  height: 0;
}
.layout-01 {
  height: 16px;
}
.layout-02 {
  height: 24px;
}
.layout-03 {
  height: 32px;
}
.layout-04 {
  height: 48px;
}
.layout-05 {
  height: 64px;
}
.layout-06 {
  height: 96px;
}
.layout-07 {
  height: 128px;
}

a {
  color: #0077CC;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  text-decoration: underline;
  font-weight: 600;
  outline:none !important;
  &:hover,
  &:focus {
      color: #005A9E;
      text-decoration: underline;
      box-shadow: none !important;
  }

  &.whiteurl {
    color: #FFF;
  }

  &.stretched-link:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background:transparent !important;
    background-color: rgba(0,0,0,0);
  }
}

@media screen and (max-width: 1200px) {
  .layout-07 {
    height: 120px;
  }
  .primary-navigation-section {
    border-bottom: 1px solid transparent !important;
  }
  .primary-navigation-container {
    border-bottom: 1px solid transparent !important;
  }
}

@media screen and (max-width: 992px) {
  .layout-02 {
    height: 16px;
  }
  .layout-03 {
    height: 24px;
  }
  .layout-04 {
    height: 32px;
  }
  .layout-05 {
    height: 48px;
  }
  .layout-06 {
    height: 64px;
  }
  .layout-07 {
    height: 80px;
  }
}

@media screen and (max-width: 768px) {
  html,
  body {
    overflow-x: hidden;
  }
  .layout-05 {
    height: 32px;
  }
  .layout-06 {
    height: 48px;
  }
  .layout-07 {
    height: 64px;
  }

  .illustration-icon-grid-item {
    padding: 0 12px;
  }
}

@media screen and (max-width: 576px) {
  .layout-07 {
    height: 48px;
  }
  .footer .no-gutters.row [class*="col-"] {
    padding: 0 16px;
  }
}

@media screen and (max-width: 575px) {
  .footer .no-gutters.row [class*="col-"] {
    padding: 0 16px !important;
  }
}


@media screen and (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    width: 100%;
    max-width: unset;
  }
  .illustration-icon-grid-item {
    padding: 0;
  }
}